"use client";
import React, { useEffect } from "react";
import { AppDispatch, RootState } from "../../reducers/store";
import { useDispatch, useSelector } from "react-redux";
import { usePathname } from "next/navigation";
import { fetchUserReadArticles } from "../../reducers/userReadArticlesSlice";
import {
  fetchLocationData,
  setSelectedLocation,
} from "../../reducers/locationDashboardSlice";
import { fetchDailyArticle } from "../../reducers/dailyArticleSlice";
import { fetchUserHuddles } from "../../reducers/userHuddlesSlice";
import { fetchArticles } from "../../reducers/articlesSlice";
import HuddleSuccess from "../huddle/HuddleSuccess";
import { UserLocation } from "@/app/_types";
import ArticleSearchDropdown from "../search/ArticleSearchDropdown";
import { handleLoginError } from "@/app/_utils/auth";
import { useNewHuddleFlow } from "../../hooks/useNewHuddleFlow";

const TopBarContent = () => {
  const pathname = usePathname();
  const dispatch = useDispatch<AppDispatch>();
  const accessToken = useSelector(
    (state: RootState) => state.login.accessToken,
  );
  const isNewHuddleFlow = useNewHuddleFlow();

  useEffect(() => {
    if (accessToken) {
      const setUserLocation = ([
        userReadArticlesResponse,
        locationDataResponse,
      ]: any) => {
        if (!localStorage.getItem("selectedLocation")) {
          // if user does not have a stored location, set it based on their user info
          const locationData = locationDataResponse;
          const userInfo = userReadArticlesResponse.userInfo;
          if (userInfo.location) {
            const selectedLocation = locationData.find(
              (location: UserLocation) => location.name === userInfo.location,
            );
            if (selectedLocation) {
              dispatch(setSelectedLocation(selectedLocation));
            }
          }
        }
      };

      dispatch(fetchDailyArticle(accessToken));

      Promise.all([
        dispatch(fetchUserReadArticles(accessToken)).unwrap(),
        dispatch(fetchLocationData(accessToken)).unwrap(),
        dispatch(fetchUserHuddles(accessToken)).unwrap(),
        dispatch(fetchArticles(accessToken)).unwrap(),
      ])
        .then(setUserLocation)
        .catch((err) => {
          // eslint-disable-next-line no-console
          console.log(
            "data initialization error",
            err.code,
            JSON.stringify({ err }),
          );
          if (err.code == "ERR_BAD_REQUEST" || err.message.includes("401")) {
            handleLoginError(dispatch);
          }
        });
    }
  }, [dispatch, accessToken]);

  return pathname.includes("/edit") ? null : (
    <>
      <div className="xl:hidden" />
      <div className="flex justify-end gap-4">
        <ArticleSearchDropdown />
      </div>
      {/* If user has edit privileges, use the new huddle flow and do not show huddle success here */}
      {!isNewHuddleFlow && <HuddleSuccess />}
    </>
  );
};

export default TopBarContent;
